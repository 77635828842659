
$theme-colors: (
  "primary": #26A65B
);

@import '~bootstrap/scss/bootstrap.scss';

.App {
  text-align: center;
}


.modal-open .modal {
  width: 100vw;
}

.modal {
  width: 100vw;
}

.ReactModal__Overlay {
  z-index: 1040 !important;
}

.ReactModal__Overlay--after-open {
  z-index: 1040;
}

.ril__image {
    max-width: 700px;
    max-height: 500px;
    transform: none !important;
}

.ril-zoom-in {
  display: none;
}

.ril-zoom-out {
  display: none;
}


.ril-close {
  color: #2ECC71 !important;
}

@media(max-width: 700px) {
  .ril__image {
    max-width: 350px;
    max-height: 250px;
  }
}



.row > div {
  padding-top: 30px;
}

.row {
  margin-top: 50px;
}

button.btn-primary {
  background-color: #2ECC71;
  border-color:  rgb(29, 194, 98);
}

button.btn-primary:hover {
  background-color: rgb(50, 87, 30);
  border-color:  #15A250;
}

.add-inventory-item, .add-inventory-item > button {
  height: 100%;
  font-size: 2rem;
}

.inventory-item .card-text {
  overflow: scroll;
  height: 150px;
}

.inventory-item {
  height: 500px;
}

.card-body .card-title {
  height: 1.6rem;
  overflow: hidden;
}

.inventory-item .card-img {
  height: 200px;
  padding: 10px;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.dropzone-preview {
  width: 100%;
}

.categoryModal {
    overflow: hidden;
    position: fixed;
    top: 60%;
    left: 50%;
    height: 700px;
    margin-top: -350px;
    transform: translateX(-50%);
}

.backstockModal {
    overflow: hidden;
    position: fixed;
    top: 60%;
    left: 50%;
    height: 400px;
    margin-top: -200px;
    transform: translateX(-50%);
}

.assignModal {
    overflow: hidden;
    position: fixed;
    top: 60%;
    left: 50%;
    height: 600px;
    margin-top: -300px;
    transform: translateX(-50%);
}

.removeZoom {
  touch-action: pan-x pan-y;
  height: 100% 
}

.ReactTable .rt-tr-group {
  flex: 0 0 auto;
}

// react-datepicker

.weekpicker .react-datepicker__week:hover {
  .react-datepicker__day {
    border-radius: 3px;
    color: whitesmoke;
    font-weight: bold;
    background: lightblue;
  }
}
